import React, { useState, useEffect, useRef } from 'react';
import cls from './layout.module.sass';
import { API_FOR_IMG, API_URL } from "shared/api/base";
import {getUserInfoThunk, telegramUsersThunk} from "../../../features/telegramUsersFetch";
import {useDispatch, useSelector} from "react-redux";
import { readMessageThunk } from "../../../features/readMessage";
import { fetchUsersData } from "../../../pages/employers/ui/employerThunk";
import {SimplePopup} from "../../../shared/ui/popup";
import {Button} from "../../../shared/ui/button";
import {getUserStatus, userStatusPatch, userStatusThunk} from "../../../features/userStatus";
import logo from 'shared/assests/images/logo.jpg'
export const Layout2 = ({ selectedChat, userId, selectedAnswer, onId, statusText, name }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [status, setStatus] = useState(null)
    const chatBoxRef = useRef(null);
    const chatSocketRef = useRef(null);
    const lastMessageRef = useRef(null);
    const getStatus = useSelector(getUserStatus)
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedChat) {
            setMessages(selectedChat);
        }
    }, [selectedChat]);

    useEffect(() => {
        chatSocketRef.current = new WebSocket(`${API_URL}ws/chat/${userId}/`);

        chatSocketRef.current.onmessage = function (e) {
            const data = JSON.parse(e.data);

            if (data.type === 'chat_message') {
                const receivedMessage = mapMessageData(data.message);
                setMessages((prevMessages) => [...prevMessages, receivedMessage]);
                displayNotification("Yangi xabar kelib tushdi!");
            }
        };

        chatSocketRef.current.onclose = function () {
            console.error('Chat ulanishi kutilmaganda uzildi');
        };

        return () => {
            if (chatSocketRef.current) {
                chatSocketRef.current.close();
            }
        };
    }, [userId]);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        if (selectedAnswer) {
            setNewMessage(selectedAnswer);
        }
    }, [selectedAnswer]);

    useEffect(() => {
        dispatch(userStatusThunk())
    }, [])

    const myStyle = {
        backgroundImage: `url(${logo})`
    }

    const mapMessageData = (apiMessageData) => {
        if (!apiMessageData) {
            return {};
        }

        const user = apiMessageData?.user || {};
        const photos = apiMessageData?.photos || [];
        const mediaGroupMessages = apiMessageData?.media_group_messages || [];

        const data = {
            id: user.id,
            message_id: user.unread_message_ids
        };
        dispatch(readMessageThunk(data));

        let text = apiMessageData.text || '';
        if (apiMessageData.deleted) {
            text = `--deleted-- ${text}`;
        }

        // currentUserId o'zgaruvchisini aniqlash kerak
        const currentUserId = userId;

        // owner maydonini aniqlash
        const isOwner = user.telegram_id === currentUserId;

        return {
            id: apiMessageData.id || null,
            text: text,
            type: apiMessageData.type || 'text',
            user: {
                id: user?.id,
                name: user?.name,
                surname: user.surname || '',
                username: user.username || '',
                telegram_id: user.telegram_id || null,
                conversation: user.conversation || '',
                status: user.status || null,
                unread_messages_count: user.unread_messages_count || 0,
                unread_message_ids: user.unread_message_ids || [],
                owner: !isOwner,
            },
            message_id: apiMessageData.message_id || null,
            date: apiMessageData.date || new Date().toISOString(),
            deleted: apiMessageData.deleted || false,
            is_read: apiMessageData.is_read || false,
            media_group_messages: mediaGroupMessages,
            media_group: apiMessageData.media_group || null,
            photos: photos,
            language: apiMessageData.language || 'text',
            entities: apiMessageData.entities || []
        };
    };

    const handleInputStatus = async (newStatus) => {
        const data = {
            status: newStatus
        }
        await dispatch(userStatusPatch({ userId: onId, data: data }))
        await dispatch(telegramUsersThunk())
    }

    const handleSendMessage = () => {
        const messageToSend = newMessage.trim();

        if (messageToSend !== '') {
            const formattedMessage = {
                text: messageToSend,
                user: {
                    'telegram_id': userId,
                    'chat_id': userId,
                    'owner': true,
                },
                type: 'text',
                date: new Date().toISOString(),
            };

            if (chatSocketRef.current) {
                chatSocketRef.current.send(JSON.stringify({
                    'message': formattedMessage
                }));
            }

            setNewMessage('');
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };
    const renderMessageContent = (message) => {
        if (!message) return null;
        const photo = message?.photos.map((item) => item?.file_path)
        console.log(photo)

        if (message.deleted) {
            return (
                <div className={cls.deletedMessage}>
                    <p>---O'chirilgan---</p>
                    <h1>{message?.text}</h1>
                </div>
            );
        }
        if (message?.user?.owner === true) {

            return (
                <div className={cls.messageInfoRight}>
                    <div className={`${cls.messageText} ${cls.messageTextRight}`}>
                        {
                            message.type === 'photo' ? <img src={`${API_FOR_IMG}${photo}`} alt=""/> :
                                message?.text
                        }
                        <small style={{ fontSize: '1rem', alignSelf: 'end' }}>
                            {new Date(message?.date).toLocaleString()}
                        </small>
                    </div>
                    <div className={cls.avatar}>
                        {message?.user?.name?.charAt(0)}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={cls.messageInfo}>
                    <div className={cls.avatar}>
                        {message?.user?.name?.charAt(0)}
                    </div>
                    <div className={`${cls.messageText} ${cls.messageTextLeft}`}>
                        {
                            message.type === 'photo' ? <img src={`${API_FOR_IMG}${photo}`} alt=""/> :
                            message?.text
                        }
                        <small style={{ fontSize: '1rem', alignSelf: 'end' }}>
                            {new Date(message?.date).toLocaleString()}
                        </small>
                    </div>
                </div>
            );
        }
    };
    const displayNotification = (message) => {
        if (Notification.permission === "granted") {
            new Notification(message);
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    new Notification(message);
                }
            });
        }
    };

    return (
        <main className={cls.main}>
            <div className={cls.main__content} style={myStyle}>
                <span className={cls.bluredBox}>
                    {selectedChat.length > 0 ? (
                        <div className={cls.chatBox} ref={chatBoxRef} style={myStyle}>
                            <span className={cls.chatBlur}>
                                <div className={cls.profileNav} >
                                <h2>{name}</h2>
                                <SimplePopup
                                    triggerContent={
                                        <div>
                                            <Button extraClass={cls.statusBtn} children={!statusText ? <h3>Status</h3> : statusText} />
                                        </div>
                                    }
                                    popupContent={
                                        <div className={cls.box}>
                                            {
                                                getStatus.map((item) => (
                                                    <span className={cls.popupMain} onClick={() => {
                                                        setStatus(item.id)
                                                        handleInputStatus(item.id)
                                                    }}>
                        <h2>{item.name}</h2>
                    </span>
                                                ))
                                            }
                                        </div>
                                    }
                                />
                            </div>
                            <div className={cls.messages}>
                                {messages.map((message, index) => (
                                    <div
                                        key={index}
                                        className={cls.message}
                                        ref={index === messages.length - 1 ? lastMessageRef : null}
                                    >
                                        {renderMessageContent(message)}
                                    </div>
                                ))}
                            </div>
                            <div className={cls.messageInputBox}>
                            <textarea
                                rows="3"
                                cols="50"
                                placeholder="Xabar"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                className={cls.messageInput}
                                onKeyDown={handleKeyDown}
                            />
                                <button onClick={handleSendMessage} className={cls.sendButton}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={cls.sendIcon}>
                                        <path d="M2 21l21-9L2 3v7l15 2-15 2v7z" />
                                    </svg>
                                </button>
                            </div>
                            </span>

                        </div>
                    ) : (
                        <p className={cls.warning}>Chatni tanlang</p>
                    )}
                </span>

            </div>
        </main>
    );
};
