import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_URL, useHttp, headers} from "../../../shared/api/base";


export const questionThunk = createAsyncThunk(
    'questions/questionThunk',
    async (questionData) => {
        const {request} = useHttp();
        return await request(`${API_URL}api/messagecontents/`, "POST", JSON.stringify(questionData), headers());
    }
);



export const fetchQuestionData = createAsyncThunk(
    'questions/fetchQuestionData',
    async () => {
        const { request } = useHttp();
        return await request(`${API_URL}api/messagecontents`, 'GET', null, headers())
    }
);


export const editQuestionData = createAsyncThunk(
    'questions/editQuestionData',
    async (id) => {
        const {request} = useHttp();
        return await request(`${API_URL}api/messagecontents/${id}/`, "GET", null, headers())
    }
)

export const changeQuestionData = createAsyncThunk(
    'questions/changeQuestionData',
    async ({id, data}) => {
        const {request} = useHttp();
        return await request(`${API_URL}api/messagecontents/${id}/`, 'PATCH', JSON.stringify(data), headers())
    }
)