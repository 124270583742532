import React, {useState} from "react";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";

import {Button} from "shared/ui/button";
import {Input} from "../../../shared/input";
import cls from "./loginPage.module.sass"
import {API_URL, useHttp} from "shared/api/base";
import {getUserData} from "features/login/model/loginSlice";
import {DefaultLoader} from "../../../shared/ui/defaultLoader";
import loginSide from 'shared/assests/images/background_logo.png'

export const Login = () => {



    const {request} = useHttp()


    const {register, handleSubmit , setValue} = useForm()
    const [inputChange, setInputChange] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const myStyle = {
        backgroundImage: `linear-gradient(${171+'deg'}, #0A2457 ${0+'%'}, #010103 ${100+'%'})`
    }

    const onClick = (e) => {

        const res = {
            username: e.username,
            password: e.password

        }

        setLoading(!loading)

        request(`${API_URL}api/token/`, "POST", JSON.stringify(res))
            .then(res => {
                dispatch(getUserData(res))
                navigate("platform/home")
                setLoading(false)
            })
            .catch(err => {

                setLoading(true)
                setError(true)
                setValue("username" , "")
                setValue("password" , "")

            })


    }
    return (
        <div className={cls.container} style={myStyle}>
            <img className={cls.loginLogo} src={loginSide} alt=""/>

            <div className={cls.login__logo}>
                {/*<img src={gennisImg} alt=""/>*/}
            </div>

            <div className={cls.login}>

                <div className={cls.login__boxes}>

                    <div className={cls.login__box}>

                        <div className={cls.box__header_img}>
                            {/*<img src={gennisImg} alt=""/>*/}
                        </div>
                        <h1 className={cls.box__header_title}>
                            login
                        </h1>
                        {error && error ?
                            <div className={cls.login__error}>Username yoki Parol noto'g'ri !</div> : null}
                        <div className={cls.box__form}>
                            <form onSubmit={handleSubmit(onClick)}>
                                {loading ?
                                    <>
                                        <Input
                                            onChange={(e) => setInputChange(e.target.value)}
                                            title={<h4 style={{color: 'white'}}>username</h4>}
                                            register={register}
                                            name={"username"}
                                            type="text"
                                            required/>


                                        <Input
                                            title={<h4 style={{color: 'white'}}>password</h4>}
                                            register={register}
                                            name={"password"}
                                            type="password"
                                            required/>
                                    </>
                                    :
                                    <>
                                        <Input

                                            disabled
                                            onChange={(e) => setInputChange(e.target.value)}
                                            title={"username"}
                                            register={register}
                                            name={"username"}
                                            type="text"
                                            required/>
                                        <Input
                                            title={"password"}
                                            register={register}
                                            name={"password"}
                                            type="password"
                                            required
                                            disabled/>
                                    </>
                                }
                                {/*<Input extraClassName={cls.checkbox} type="checkbox" onChange={() => setInputChange}*/}
                                {/*       checkboxTitle={"Remember me"}/>*/}
                                {/*<Button extraClass={cls.login__btn}>Login</Button>*/}
                                {loading ?
                                    <Button extraClass={cls.login__btn}>Login</Button> :
                                    <DefaultLoader/>}
                                {/*{loading ? null : <DefaultLoader/>}*/}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}