
import {configureStore} from "@reduxjs/toolkit";
import {employerSlice} from "pages/employers";
import {questionSlice} from "pages/questions";
import {questionDeleteModalSlice} from "features/questionDeleteModal";
import {telegramUsersSlice} from "features/telegramUsersFetch";
import {languageAddSlice} from "features/languageAddModal";
import {fetchLanguagesSlice} from "features/languageAddModal";
import {employerToolModalSlice} from "features/employerToolModal";
import {readMessageSlice} from "features/readMessage";
import {userStatusSlice} from "../features/userStatus";
import {loginSlice} from "../features/login";

const stringMiddleware = () => (next) => (action) => {
    if (typeof action === 'string') {
        return next({
            type: action
        })
    }
    return next(action)
}


export const store = configureStore({
    reducer: {
        employerSlice,
        questionSlice,
        questionDeleteModalSlice,
        telegramUsersSlice,
        languageAddSlice,
        fetchLanguagesSlice,
        employerToolModalSlice,
        readMessageSlice,
        userStatusSlice,
        loginSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            stringMiddleware
        ),
    devTools: process.env.NODE_ENV !== "production",
})

