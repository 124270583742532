import {createSlice} from "@reduxjs/toolkit";
import {languageAddThunk,} from "./languageAddThunk";

const initialState ={
    languages: [],
    loading: false,
    error: null
}


const languageAddSlice = createSlice({
    name: 'languageAddSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(languageAddThunk.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(languageAddThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.languages = action.payload;
                state.error = null
            })
            .addCase(languageAddThunk.rejected, (state,action) => {
                state.loading = false;
                state.error = action.error.message
            })


    }
})

export default languageAddSlice.reducer