import {createSlice} from "@reduxjs/toolkit";
import {employerToolModalThunk, employerEditToolModalThunk, employerDeleteToolModalThunk} from './employerToolModalThunk'

const initialState = {
    profile: [],
    edit: [],
    deleted: [],
    loading: false,
    error: null
}

const employerToolModalSlice = createSlice({
    name: "employerToolModalSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(employerToolModalThunk.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(employerToolModalThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.profile = action.payload;
                state.error = null
            })
            .addCase(employerToolModalThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })

            .addCase(employerEditToolModalThunk.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(employerEditToolModalThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.edit = action.payload;
                state.error = null
            })
            .addCase(employerEditToolModalThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })


            .addCase(employerDeleteToolModalThunk.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(employerDeleteToolModalThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.deleted = action.payload;
                state.error = null
            })
            .addCase(employerDeleteToolModalThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })
    }
})

export default employerToolModalSlice.reducer