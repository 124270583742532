import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_URL, headers, useHttp} from "../../../shared/api/base";

export const languageAddThunk = createAsyncThunk(
    'languageAddSlice/languageAddThunk',
    async (data) => {
        const {request} = useHttp()
        return request(`${API_URL}api/languages/`, "POST", JSON.stringify(data), headers())
    }
)
