import React from 'react';
import {Outlet} from "react-router";
import {Menubar} from "../../../widgets";
import cls from './layout.module.sass'


export const Layout = () => {


    return (
        <>
            <Menubar/>
            <main className={cls.main}>
                <div className={cls.main__content}>
                    <Outlet/>
                </div>
            </main>
        </>
    );
};