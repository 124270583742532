import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_URL, headers, useHttp} from "../../../shared/api/base";

export const telegramUsersThunk = createAsyncThunk(
    'telegramUsersSlice/telegramUsersThunk',
    async () => {
        const {request} = useHttp();
        return await request(`${API_URL}api/TelegramUsers/`, "GET", null, headers())
    }
)

export const getUserInfoThunk = createAsyncThunk(
    'telegramUsersSlice/getuserInfoThunk',
    async (id) => {
        const {request} = useHttp();
        return await request(`${API_URL}api/users_message/${id}/`, "GET", null, headers())
    }
)