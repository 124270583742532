import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_URL, headers, useHttp} from "../../../shared/api/base";


export const readMessageThunk = createAsyncThunk(
    'readMessageSlice/readMessageThunk',
    async (data) => {
        const {request} = useHttp();
        return await request(`${API_URL}api/read_user_messages`, "POST", JSON.stringify(data), headers())
    }
)