import React, {useEffect, useState} from 'react';
import {Modal} from "../../../shared/ui/modal";
import cls from './questionAddModal.module.sass'
import {Input} from "shared/input";
import {Button} from "shared/ui/button";
import {questionThunk} from "../../../pages/questions";
import {useDispatch, useSelector} from "react-redux";
import {Textarea} from "../../../shared/textArea";
import {fetchLanguagesThunk,getLang} from "../../languageAddModal";
import {Select} from "../../../shared/ui/select";

export const QuestionAddModal = ({active, setActive}) => {
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [lang, setLang] = useState('uz');  // Default til - Uzbek
    const dispatch = useDispatch();
    const langs = useSelector(getLang)
    useEffect(() => {
        dispatch(fetchLanguagesThunk())
    }, [])
    const handleAddQuestion = () => {
        const newQuestion = {
            name,
            text,
            content: 1,
            lang: lang
        }

        dispatch(questionThunk(newQuestion))
        setActive(false)
    }

    return (
        <Modal
            active={active}
            setActive={setActive}
        >
            <div className={cls.filter}>
                <h1>Qo'shish</h1>
                <div className={cls.filterBox}>
                    <div className={cls.colsRaw}>
                        <Input
                            title={"Savol"}
                            extraClassName={cls.questionInt}
                            placeholder={"Savol"}
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Textarea
                            extraClassName={cls.questionInt}
                            value={text}
                            required
                            onChange={(e) => setText(e.target.value)}
                        />
                        {/*<select*/}
                        {/*    className={cls.langSelect}*/}
                        {/*    value={lang}*/}
                        {/*    onChange={(e) => setLang(e.target.value)}*/}
                        {/*>*/}
                        {/*    <option value="uz">O'zbek</option>*/}
                        {/*    <option value="ru">Rus</option>*/}
                        {/*</select>*/}
                        <Select
                            extraClass={cls.langSelect}
                            options={langs}
                            onChangeOption={setLang}
                        />
                    </div>
                </div>
                <div className={cls.buttonPanel}>
                    <Button extraClass={cls.cancel} onClick={() => setActive(false)} children={"Bekor qilish"}/>
                    <Button extraClass={cls.add} children={"Qo'shish"} onClick={handleAddQuestion}/>
                </div>
            </div>
        </Modal>
    );
};
