
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router";
import {getUserRefreshLoading,userRefreshData, getUserFetchError} from 'features/login';
import {DefaultPageLoader} from "../../../shared/ui/defaultLoader";


export const RequireAuth = ({roles, children}) => {
    const refresh_token = sessionStorage.getItem("refresh_token")
    const refreshLoading = useSelector(getUserRefreshLoading)
    const error = useSelector(getUserFetchError)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(userRefreshData({refresh: refresh_token}))


    }, [])



    if (refreshLoading) {
        return <DefaultPageLoader/>
    } else if (error) {
        return <Navigate to={"/"}/>
    } else return <Outlet/>
};