import {StoreProvider} from "./providers";
import {AppRouter} from "./routers";


function App() {
  return (
      <StoreProvider>
            <AppRouter/>
      </StoreProvider>
  );
}

export default App;
