import { useCallback, useState } from 'react';
import { Link } from "../../../shared/ui/link";
import { routersConfig } from "../../../app/routers";
import cls from "./menuBar.module.sass";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../../../shared/ui/button";
import {useNavigate} from "react-router";
export const Menubar = () => {
    const [active, setActive] = useState(false);

    const navigate = useNavigate()
    const onClickExit = () => {
        navigate("/")
        sessionStorage.clear()
        localStorage.clear()
    }

    const renderMultipleMenu = useCallback(() => {
        return routersConfig.map((item, index) => {
            if (item.isMenu)
                return (
                    <>
                        <li key={index} className={cls.link}>
                            <Link
                                to={item.to}
                                extraClass={cls.link__href}
                                activeClass={cls.active}
                                onClick={() => setActive(item.name)}
                            >
                                <FontAwesomeIcon icon={item.icon} className={cls.iconLink} />
                                {/*<span className={cls.link__title}>{item.name}</span>*/}
                            </Link>
                        </li>
                    </>
                );
            else return null;
        });
    });

    const renderedMenu = renderMultipleMenu();

    return (
        <nav className={cls.menu}>
            <ul className={cls.menu__inner}>
                {renderedMenu}
            </ul>
            <Button onClick={onClickExit} extraClass={cls.button} children={<FontAwesomeIcon icon={faPowerOff}/>} />
        </nav>
    );
};
