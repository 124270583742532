import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_URL, useHttp, headers} from "../../../shared/api/base";



export const questionDeleteModalThunk = createAsyncThunk(
    'questionDeleteModalSlice/questionDeleteModalThunk',
    async (id) => {
        const {request} = useHttp();
        return await request(`${API_URL}api/messagecontents/${id}/`, "DELETE", null, headers())
    }
)