import React, {useEffect, useState} from 'react';
import cls from './questionPage.module.sass'
import {Button} from "shared/ui/button";
import {QuestionList} from "../../../entities/questionList";
import {QuestionAddModal} from "../../../features/questionAddModal";
import {QuestionAnswerModal} from "../../../features/questionAnswerModal";
import {LanguageAddModal} from "../../../features/languageAddModal";
import {getPostLoading} from "../../../features/languageAddModal";
import {useSelector} from "react-redux";
import {DefaultLoader} from "../../../shared/ui/defaultLoader";

export const QuestionPage = () => {
    const [active, setActive] = useState(false)
    const [portal, setPortal] = useState(false)
    const loadingDef = useSelector(getPostLoading)


    return (
        <div className={cls.main}>
            <div className={cls.main__firstBox}>
                <h1>Qisqa javoblar</h1>
                <div className={cls.box}>
                    <Button extraClass={cls.button} children={"Javob qo'shish"} onClick={() => setActive(!active)}/>
                    <Button children={"Til qo'shish"} extraClass={cls.buttons} onClick={() => setPortal(!portal)}/>
                </div>

            </div>
            <div className={cls.main__tableBox}>
                {
                    loadingDef ? <DefaultLoader/> :
                        <div className={cls.main__tableBox__table}>
                            <QuestionList/>
                        </div>
                }

                <QuestionAddModal active={active} setActive={setActive}/>
                <LanguageAddModal portal={portal} setPortal={setPortal}/>
            </div>
        </div>
    );
};
