import {createSlice} from "@reduxjs/toolkit";
import {telegramUsersThunk, getUserInfoThunk} from "./telegramUsersThunk";

const initialState = {
    users: [],
    message: [],
    loading: false,
    error: null
}

const telegramUsersSlice = createSlice({
    name: 'telegramUsersSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(telegramUsersThunk.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(telegramUsersThunk.fulfilled, (state, action) => {
                state.loading = false
                state.users = action.payload
                state.error = null
            })
            .addCase(telegramUsersThunk.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })


            .addCase(getUserInfoThunk.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(getUserInfoThunk.fulfilled, (state, action) => {
                state.loading = false
                state.message = action.payload
                state.error = null
            })
            .addCase(getUserInfoThunk.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    }
})

export default telegramUsersSlice.reducer