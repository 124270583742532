import React, {useState} from 'react';
import cls from './employerPage.module.sass'
import {Button} from "../../../shared/ui/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {EmployerList} from "../../../entities/emplyerList";
import {EmployerAddModal} from "../../../features/employerAddModal";
export const EmployerPage = () => {
    const [active, setActive] = useState(false)
    return (
        <div className={cls.main}>
            <div className={cls.main__firstBox}>
                <h1>Xodimlar</h1>
                <Button extraClass={cls.button} children={
                    <>
                        <FontAwesomeIcon className={cls.icons} icon={faPlus}/>
                        <h3>Qo'shish</h3>
                    </>

                } onClick={() => setActive(!active)}/>
            </div>
            <div className={cls.main__tableBox}>
                <div className={cls.main__tableBox__table}>
                    <EmployerList/>
                </div>
                <EmployerAddModal active={active} setActive={setActive}/>
            </div>
        </div>
    );
};
