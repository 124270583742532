import {createSlice} from "@reduxjs/toolkit";
import {fetchQuestionData, questionThunk, editQuestionData, changeQuestionData} from "./questionThunk";

const initialState =
    {
        questions: [],
        ques: [],
        questionsEdit: [],
        loading: false,
        error: null,
        status: 'idle',
    }

const questionSlice = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers:(builder) => {
        builder
            .addCase(questionThunk.pending, (state) => {
                state.loading = true
            })
            .addCase(questionThunk.fulfilled, (state, action) => {
                state.status = "success";
                state.loading = false;
                state.questions.push(action.payload);
            })

            .addCase(questionThunk.rejected, (state, action) => {
                state.error = "error";
                state.error = action.error.message
            })
            .addCase(fetchQuestionData.pending, state => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchQuestionData.fulfilled,( state, action )=> {
                state.loading = false;
                state.questions = action.payload
                state.roomsStatus = 'success'


            })
            .addCase(fetchQuestionData.rejected, state => {
                state.error = "error"
            })
            .addCase(editQuestionData.pending, (state) => {
                state.loading  = true;
                state.error = null
            })
            .addCase(editQuestionData.fulfilled, (state, action) => {
                state.loading = false;
                state.ques = action.payload;
                state.error = null
            })
            .addCase(editQuestionData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })
            .addCase(changeQuestionData.pending, (state) => {
                state.loading  = true;
                state.error = null
            })
            .addCase(changeQuestionData.fulfilled, (state, action) => {
                state.loading = false;
                state.questionsEdit = action.payload;
                state.error = null
            })
            .addCase(changeQuestionData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })
    }
})

export default questionSlice.reducer