import React, { useEffect, useState } from 'react';
import cls from './chatPage.module.sass';
import { SearchInput } from "../../../shared/ui/searchInput";
import { ChatList } from "../../../entities/chatList";
import { Layout2 } from "../../../app/layout2";
import { useSelector } from "react-redux";
import { getQuestionsData } from "../../questions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faMailReply } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../shared/ui/button";
import { useDispatch } from "react-redux";
import { getUsers, telegramUsersThunk, getUserInfoThunk, getMessage } from "../../../features/telegramUsersFetch";
import {fetchQuestionData} from "../../questions";
import {getUserStatus, userStatusThunk} from "../../../features/userStatus";
import {SimplePopup} from "../../../shared/ui/popup";
import {queryFilterUserThunk} from "../../employers";

export const ChatPage = () => {
    const [search, setSearch] = useState("");
    const [searched, setSearched] = useState("")
    const [selectedChat, setSelectedChat] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(1);
    const [userID, setUserID] = useState(null)
    const [statusText, setStatusText] = useState('')
    const [expandedDivs, setExpandedDivs] = useState({});
    const [activeLanguage, setActiveLanguage] = useState('uz');
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([])
    const [name, setName] = useState('')
    const dispatch = useDispatch();
    const questionData = useSelector(getQuestionsData);
    const getStatus = useSelector(getUserStatus)
    const usersData = useSelector(getUsers);
    const messageData = useSelector(getMessage);
    const [selectedQuestion, setSelectedQuestion] = useState('');

    const handleClick = (index) => {
        setExpandedDivs(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };


    useEffect(() => {
        dispatch(userStatusThunk())
    }, [])


    useEffect(() => {
        dispatch(telegramUsersThunk());
    }, [dispatch]);

    useEffect(() => {
        if (selectedUser) {
            dispatch(getUserInfoThunk(selectedUser));
        }
    }, [selectedUser, dispatch]);

    useEffect(() => {
        dispatch(fetchQuestionData());
    }, [dispatch]);

    useEffect(() => {
        const filtered = questionData.filter(item => {
            const matchesLanguage = activeLanguage === 'uz' ? item.lang === 1 : item.lang === 2;
            const matchesSearch = item.name.toLowerCase().includes(searched.toLowerCase());
            return matchesLanguage && matchesSearch;
        });
        setFilteredQuestions(filtered);
    }, [questionData, activeLanguage, searched]);

    useEffect(() => {
        const filtered = usersData.filter(item => {
            const searchedUsers = item?.username.toLowerCase().includes(search.toLowerCase()) ||
                item.name.toLowerCase().includes(search.toLowerCase())
            return searchedUsers
        })
        setFilteredUsers(filtered)
    }, [usersData, search])

    const handleLanguageSelect = (language) => {
        setActiveLanguage(language);
    };
    const handleFilter = async (newId) => {
       await dispatch(queryFilterUserThunk(newId))
    }

    return (
        <div className={cls.main}>
            <div className={cls.leftPanel}>
                <div className={cls.leftPanel_articleBox}>
                    <h1 className={cls.leftPanel_articleBox_article}>Xabarlar</h1>
                    <SimplePopup
                        triggerContent={
                            <div>
                                <span className={cls.moreBox}>◦◦◦</span>
                            </div>
                        }
                        popupContent={
                            <div className={cls.box}>
                                {
                                    getStatus.map((item) => (
                                        <span className={cls.popupMain}
                                        onClick={() => {
                                            setSelectedId(item.id)
                                            handleFilter(item.id)
                                        }}
                                        >
                        <p style={{fontSize: 19+'px'}}>{item.name}</p>
                    </span>
                                    ))
                                }
                            </div>
                        }
                    />
                </div>
                <div className={cls.leftPanel_searchBox}>
                    <SearchInput setSearch={setSearch} />

                </div>
                <div className={cls.leftPanel_chatTableBox}>
                    <ChatList currentTableData={filteredUsers} onSelectChat={setSelectedChat} onUserId={setSelectedUser} onId={setUserID} setStatusText={setStatusText} setName={setName}/>
                </div>
            </div>

            <Layout2 selectedChat={messageData} userId={selectedUser} selectedAnswer={selectedQuestion} onId={userID} statusText={statusText} name={name}/>

            <div className={cls.quickRepBox}>
                <div className={cls.quickRepBox_searchBox}>
                    <SearchInput setSearch={setSearched} />
                    <div className={cls.buttonPanel}>
                        <Button
                            extraClass={`${cls.buttonUz} ${activeLanguage === 'uz' ? cls.active : ''}`}
                            onClick={() => handleLanguageSelect('uz')}
                            children={"uz"}
                        />
                        <Button
                            extraClass={`${cls.buttonUz} ${activeLanguage === 'ru' ? cls.active : ''}`}
                            onClick={() => handleLanguageSelect('ru')}
                            children={"ru"}
                        />
                    </div>
                </div>
                <div className={cls.listGroups}>
                    {
                        filteredQuestions.map((item, index) => (
                            <div className={cls.listAnsDivMain} key={index}>
                                <div
                                    className={cls.listAnsDiv}
                                    style={{
                                        height: expandedDivs[index] ? '70rem' : '7rem'
                                    }}
                                    onClick={() => handleClick(index)}
                                >
                                    <div className={cls.topBox}>
                                        <h2>{item.name}</h2>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                    <div className={cls.ansBox}>
                                        <h2>{item.text}</h2>
                                    </div>

                                </div>
                                <Button
                                    extraClass={cls.sendBtn}
                                    children={<FontAwesomeIcon icon={faMailReply} />}
                                    onClick={() => setSelectedQuestion(item.text)}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};
