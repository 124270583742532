import {createSlice} from "@reduxjs/toolkit";
import {fetchLanguagesThunk} from "./fetchLanguagesThunk";


const initialState = {
    lang: [],
    loading: false,
    error: null
}

const fetchLanguagesSlice = createSlice({
    name: "fetchLanguagesSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchLanguagesThunk.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(fetchLanguagesThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.lang = action.payload;
                state.error = null
            })
            .addCase(fetchLanguagesThunk.rejected, (state,action) => {
                state.loading = false;
                state.error = action.error.message
            })
    }
})

export default fetchLanguagesSlice.reducer