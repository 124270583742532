import React, {useState} from 'react';
import {Modal} from "../../../shared/ui/modal";
import cls from './questionAddModal.module.sass'
import {Input} from "shared/input";
import {Button} from "shared/ui/button";
import {useDispatch} from "react-redux";
import {languageAddThunk} from "../model/languageAddThunk";
import {fetchLanguagesThunk} from "../model/fetchLanguagesThunk";


export const LanguageAddModal = ({portal, setPortal}) => {
    const [name, setName] = useState('');
    const dispatch = useDispatch();

    const handleAddQuestion = () => {
        const newLanguage = {
            name: name,
        }

        dispatch(languageAddThunk(newLanguage))
        dispatch(fetchLanguagesThunk())
        setPortal(false)
    }

    return (
        <Modal
            active={portal}
            setActive={setPortal}
        >
            <div className={cls.filter}>
                <h1>Qo'shish</h1>
                <div className={cls.filterBox}>
                    <div className={cls.colsRaw}>
                        <Input
                            title={"Til "}
                            extraClassName={cls.questionInt}
                            placeholder={"Til qo'shing"}
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className={cls.buttonPanel}>
                    <Button extraClass={cls.cancel} onClick={() => setPortal(false)} children={"Bekor qilish"}/>
                    <Button extraClass={cls.add} children={"Qo'shish"} onClick={handleAddQuestion}/>
                </div>
            </div>
        </Modal>
    );
};
