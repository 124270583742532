import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_URL, headers, useHttp} from "../../../shared/api/base";


export const employerToolModalThunk = createAsyncThunk(
    'employerToolModalSlice/employerToolModalThunk',
    async (id) => {
        const {request} = useHttp();
        return request(`${API_URL}api/users/${id}/`, "GET", null, headers())
    }
)

export const employerEditToolModalThunk = createAsyncThunk(
    'employerToolModalSlice/employerEditToolModalThunk',
    async ({id, data}) => {
        const {request} = useHttp();
        return request(`${API_URL}api/users/${id}/`, "PUT", JSON.stringify(data), headers())
    }
)

export const employerDeleteToolModalThunk = createAsyncThunk(
    'employerToolModalSlice/employerDeleteToolModalThunk',
    async (id) => {
        const {request} = useHttp();
        return request(`${API_URL}api/users/${id}/`, "DELETE", null, headers())
    }
)