import React, {Suspense} from 'react';
import {createRoutesFromElements, Navigate, Route, RouterProvider} from "react-router";
import {createBrowserRouter} from "react-router-dom";
import classNames from "classnames";
import {routersConfig} from "../config/routersConfig";
import {Layout} from "../../layout/layout/layout";
import {HomePage} from "../../../pages/homePage";


import "../../styles/index.sass"
import {RequireAuth} from "./RequireAuth";
import {Login} from "../../../pages/loginPage/ui/loginPage";
export const AppRouter = () => {


    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    path={"/"}
                    element={<Login/>}
                />

                <Route element={<RequireAuth/>}>

                    <Route path={"platform/*"} element={<Layout/>}>

                        {
                            routersConfig.map(item =>
                                <Route
                                    key={item.name}
                                    path={item.path}
                                    element={
                                        item.element
                                    }
                                />
                            )
                        }

                        {/*<Route*/}
                        {/*    index*/}
                        {/*    element={<Navigate to={"home"}/>}*/}
                        {/*/>*/}

                    </Route>

                </Route>



            </>
        )
    );

    return (
        <div className={classNames("app")}>
            <Suspense>
                <RouterProvider router={router}/>
            </Suspense>
        </div>
    );
};
