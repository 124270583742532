import {faHouse, faUser, faComments, faTasks } from '@fortawesome/free-solid-svg-icons';

import {
    getHome,
    getEmployers,
    getChats, getQuestion
}
    from "shared/const/routers";
import {HomePage} from "pages/homePage";
import {EmployerPage} from "pages/employerPage";
import {ChatPage} from "pages/chatPage";
import {QuestionPage} from "../../../pages/questionPage";


export const routersConfig = [
    {
        to: "home",
        path: getHome(),
        icon: faHouse,
        element: <HomePage />,
        isMenu: true
    },
    {
        to: "employers",
        path: getEmployers(),
        icon: faUser,
        element: <EmployerPage />,
        isMenu: true
    },
    {
        to: "chat",
        path: getChats(),
        icon: faComments,
        element: <ChatPage />,
        isMenu: true
    },
    {
        to: "question",
        path: getQuestion(),
        icon: faTasks,
        element: <QuestionPage/>,
        isMenu: true
    },
];
