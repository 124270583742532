import {createSlice} from "@reduxjs/toolkit";
import {questionDeleteModalThunk} from "./questionDeleteModalThunk";

const initialState = {
    deleted: [],
    loading: false,
    error: null
}

const questionDeleteSlice = createSlice({
    name: "questionDeleteSlice",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(questionDeleteModalThunk.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(questionDeleteModalThunk.fulfilled, (state, action) => {
                state.loading = false
                state.deleted = action.payload
                state.error = null
            })
            .addCase(questionDeleteModalThunk.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    }
})

export default questionDeleteSlice.reducer