import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_URL, headers, useHttp} from "../../../shared/api/base";


export const userStatusThunk = createAsyncThunk(
    'userStatusSlice/userStatusThunk',
    async () => {
        const {request} = useHttp();
        return await request(`${API_URL}api/UserStatus/`, "GET", null, headers())
    }
)

export const userStatusPatch = createAsyncThunk(
    'userStatusSlice/userStatusPatch',
    async ({userId, data}) =>  {
        const {request} = useHttp();
        return await request(`${API_URL}api/telegram_user_status/${userId}/`, "PATCH", JSON.stringify(data), headers())
    }
)