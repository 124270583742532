import {createSlice} from "@reduxjs/toolkit";
import {userStatusPatch, userStatusThunk} from "./userStatusThunk";

const initialState = {
    status: [],
    pathUser: [],
    loading: false,
    error: false
}

const userStatusSlice = createSlice({
    name: 'userStatusSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(userStatusThunk.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(userStatusThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.status = action.payload;
                state.error = null
            })
            .addCase(userStatusThunk.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })
            .addCase(userStatusPatch.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(userStatusPatch.fulfilled, (state, action) => {
                state.loading = false;
                state.pathUser = action.payload;
                state.error = null
            })
            .addCase(userStatusPatch.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })
    }
})

export default userStatusSlice.reducer