import React, { useEffect, useState } from 'react';
import { Table } from "shared/ui/table";
import { fetchUsersData } from "../../../pages/employers/ui/employerThunk";
import { getUserssData } from "../../../pages/employers";
import { useDispatch, useSelector } from "react-redux";
import cls from './employerList.module.sass';
import { SimplePopup } from "../../../shared/ui/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {EmployerDeleteToolModal, EmployerToolModal} from "../../../features/employerToolModal";

export const EmployerList = () => {
    const dispatch = useDispatch();
    const usersData = useSelector(getUserssData);
    const [open, setOpen] = useState(false);
    const [portal, setPortal] = useState(false)
    const [userId, setUserId] = useState();

    useEffect(() => {
        dispatch(fetchUsersData());
    }, [dispatch]);

    const sortedUsersData = [...usersData].sort((a, b) => a.id - b.id);

    const renderVacancies = () => {
        return sortedUsersData.map((item) => (
            <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name} {item.surname}</td>
                <td>{item.username}</td>
                <td>{item.date_joined}</td>
                <td className={cls.toolMain}>
                    <SimplePopup
                        triggerContent={<div className={cls.toolBox}><span>...</span></div>}
                        popupContent={
                            <div className={cls.popupBody}>
                                <div className={cls.editBox} onClick={() => {
                                    setOpen(true);
                                    setUserId(item.id);
                                }}>
                                    <FontAwesomeIcon icon={faEdit} />
                                    <p>Tahrirlash</p>
                                </div>
                                <div className={cls.delBox} onClick={() => {
                                    setPortal(true);
                                    setUserId(item.id);
                                }}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                    <p>O'chirish</p>
                                </div>
                            </div>
                        }
                    />
                </td>
            </tr>
        ));
    };

    return (
        <Table>
            <thead>
            <tr>
                <th>№</th>
                <th>Ism familiya</th>
                <th>Login</th>
                <th>Qo'shilgan sanasi</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {renderVacancies()}
            </tbody>
            <EmployerDeleteToolModal isOpen={portal} onClose={setPortal} roomId={userId}/>
            <EmployerToolModal open={open} setOpen={setOpen} userId={userId} />
        </Table>
    );
};
