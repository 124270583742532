import React, {useEffect, useState} from 'react';
import {Modal} from "../../../shared/ui/modal";
import cls from './questionAnswerModal.module.sass';
import {Input} from "shared/input";
import {Button} from "shared/ui/button";
import {Textarea} from "../../../shared/textArea";
import {changeQuestionData, editQuestionData, fetchQuestionData} from "../../../pages/questions";
import { getQuestionData} from "../../../pages/questions";
import {useDispatch, useSelector} from "react-redux";
import {Select} from "../../../shared/ui/select";
import {fetchLanguagesThunk, getLang} from "../../languageAddModal";

export const QuestionAnswerModal = ({actives, setActives, selectedQuestionId}) => {
    const dispatch = useDispatch();
    const [question, setQuestion] = useState('');
    const [text, setText] = useState('')
    const getData = useSelector(getQuestionData)
    const langs = useSelector(getLang)
    const [lang, setLang] = useState('uz');
    useEffect(() => {
        dispatch(fetchLanguagesThunk())
    }, [])

    useEffect(() => {
        if (selectedQuestionId && actives){
            dispatch(editQuestionData(selectedQuestionId))
        }
    }, [selectedQuestionId, actives])

    useEffect(() => {
        if (getData && getData.id === selectedQuestionId){
            setQuestion(getData.name || '');
            setText(getData.text || '')
            setLang(getData.lang || '')
        }
    }, [getData, selectedQuestionId])

    const handleChangeAnswer  = async () => {
        if (!selectedQuestionId) return;

        const data = {
            name: question,
            text: text,
            lang: lang
        };

        await dispatch(changeQuestionData({id: selectedQuestionId, data: data}))
        await dispatch(fetchQuestionData())
        setActives(false)
    };


    return (
        <Modal
            active={actives}
            setActive={setActives}
        >
            <div className={cls.filter}>
                <h1>Tahrirlash</h1>
                <div className={cls.filterBox}>
                    <div className={cls.colsRaw}>
                        <Input
                            title={"Javob"}
                            extraClassName={cls.questionInt}
                            placeholder={"Savol"}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                        <Textarea
                            placeholder={"Javob matnini kiriting"}
                            value={text}
                            onChange={(e) => setText(e.target.value)}

                        />
                        <Select
                            extraClass={cls.langSelect}
                            options={langs}
                            defaultValue={lang}
                            onChangeOption={setLang}
                        />
                    </div>
                </div>
                <div className={cls.buttonPanel}>
                    <Button extraClass={cls.cancel} onClick={() => setActives(false)} children={"Bekor qilish"}/>
                    <Button
                        extraClass={cls.add}
                        onClick={handleChangeAnswer}
                        children={"O'zgartirish"}
                    />
                </div>
            </div>
        </Modal>
    );
};
